<template>
  <div>
    <div v-if="users" class="position-table">
      <md-table v-model="users">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="ID" md-sort-by="id">
            <span>{{ item.id }}</span>
          </md-table-cell>
          <md-table-cell md-label="Роль">
            <span class="text ws-nowrap">{{ item.name }}</span>
          </md-table-cell>
          <md-table-cell>
            <div class="edit-and-delete-block">
              <md-button class="md-just-icon md-simple md-primary">
                <md-icon>edit</md-icon>
                <md-tooltip md-direction="top">Редактировать</md-tooltip>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <Pagination
        :current-page="pagination.current_page"
        :last-page="pagination.last_page"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/ui/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      pagination: {
        current_page: 1,
        last_page: 2,
      },
      users: [
        {
          id: 1,
          name: "администратор пользователей",
        },
        {
          id: 2,
          name: "администратор поддержки",
        },
        {
          id: 3,
          name: "администратор запрещенных слов",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.text {
  word-break: break-word;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages {
  margin-right: 20px;
  margin-left: 20px;
}
</style>
